var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import icon from "src/decidim/icon";
export default class ExternalLink {
  static configureMessages(messages) {
    this.MESSAGES = __spreadValues(__spreadValues({}, this.MESSAGES), messages);
  }
  constructor(node) {
    this.MESSAGES = {
      externalLink: "External link"
    };
    if (node.closest(".editor-container")) {
      return;
    }
    if (!node.querySelector("span[data-external-link]")) {
      this.setup(node);
    }
  }
  setup(node) {
    const span = document.createElement("span");
    span.dataset.externalLink = true;
    span.innerHTML = `${this.generateIcon()}${this.generateScreenReaderLabel()}`;
    span.classList.add("inline-block", "mx-0.5");
    return node.appendChild(span);
  }
  generateIcon() {
    return icon("external-link-line", { class: "fill-current" });
  }
  generateScreenReaderLabel() {
    return `<span class="sr-only">(${this.MESSAGES.externalLink})</span>`;
  }
}
