import Rails from "@rails/ujs";
class ConfirmDialog {
  constructor(sourceElement) {
    this.$modal = $("#confirm-modal");
    this.$source = sourceElement;
    this.$content = $("[data-confirm-modal-content]", this.$modal);
    this.$buttonConfirm = $("[data-confirm-ok]", this.$modal);
    this.$buttonCancel = $("[data-confirm-cancel]", this.$modal);
    window.Decidim.currentDialogs["confirm-modal"].open();
  }
  confirm(message) {
    this.$content.html(message);
    this.$buttonConfirm.off("click");
    this.$buttonCancel.off("click");
    return new Promise((resolve) => {
      this.$buttonConfirm.on("click", (ev) => {
        ev.preventDefault();
        window.Decidim.currentDialogs["confirm-modal"].close();
        resolve(true);
        this.$source.focus();
      });
      this.$buttonCancel.on("click", (ev) => {
        ev.preventDefault();
        window.Decidim.currentDialogs["confirm-modal"].close();
        resolve(false);
        this.$source.focus();
      });
    });
  }
}
const allowAction = (ev, element) => {
  const message = $(element).data("confirm");
  if (!message) {
    return true;
  }
  if (!Rails.fire(element, "confirm")) {
    return false;
  }
  const dialog = new ConfirmDialog(
    $(element)
  );
  dialog.confirm(message).then((answer) => {
    const completed = Rails.fire(element, "confirm:complete", [answer]);
    if (answer && completed) {
      $(element).data("confirm", null);
      $(element).removeAttr("data-confirm");
      if (ev.type === "click" && ($(element).is('button[type="submit"]') || $(element).is('input[type="submit"]'))) {
        $(element).parents("form").submit();
      } else {
        let origEv = ev.originalEvent || ev;
        let newEv = origEv;
        if (typeof Event === "function") {
          newEv = new origEv.constructor(origEv.type, origEv);
        }
        ev.target.dispatchEvent(newEv);
      }
    }
  });
  return false;
};
const handleConfirm = (ev, element) => {
  if (!allowAction(ev, element)) {
    Rails.stopEverything(ev);
  }
};
const getMatchingEventTarget = (ev, selector) => {
  let target = ev.target;
  while (!(!(target instanceof Element) || Rails.matches(target, selector))) {
    target = target.parentNode;
  }
  if (target instanceof Element) {
    return target;
  }
  return null;
};
const handleDocumentEvent = (ev, matchSelectors) => {
  return matchSelectors.some((currentSelector) => {
    let target = getMatchingEventTarget(ev, currentSelector);
    if (target === null) {
      return false;
    }
    handleConfirm(ev, target);
    return true;
  });
};
document.addEventListener("click", (ev) => {
  return handleDocumentEvent(ev, [
    Rails.linkClickSelector,
    Rails.buttonClickSelector,
    Rails.formInputClickSelector
  ]);
});
document.addEventListener("change", (ev) => {
  return handleDocumentEvent(ev, [Rails.inputChangeSelector]);
});
document.addEventListener("submit", (ev) => {
  return handleDocumentEvent(ev, [Rails.formSubmitSelector]);
});
document.addEventListener("DOMContentLoaded", function() {
  $(Rails.formInputClickSelector).on("click.confirm", (ev) => {
    handleConfirm(ev, getMatchingEventTarget(ev, Rails.formInputClickSelector));
  });
});
