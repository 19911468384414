/*! modernizr 3.3.1 (Custom Build) | MIT *
 * https://modernizr.com/download/?-setclasses !*/
!function(n, e, s) {
  function o(n2, e2) {
    return typeof n2 === e2;
  }
  function a() {
    var n2, e2, s2, a2, i2, l2, r2;
    for (var c2 in f)
      if (f.hasOwnProperty(c2)) {
        if (n2 = [], e2 = f[c2], e2.name && (n2.push(e2.name.toLowerCase()), e2.options && e2.options.aliases && e2.options.aliases.length))
          for (s2 = 0; s2 < e2.options.aliases.length; s2++)
            n2.push(e2.options.aliases[s2].toLowerCase());
        for (a2 = o(e2.fn, "function") ? e2.fn() : e2.fn, i2 = 0; i2 < n2.length; i2++)
          l2 = n2[i2], r2 = l2.split("."), 1 === r2.length ? Modernizr[r2[0]] = a2 : (!Modernizr[r2[0]] || Modernizr[r2[0]] instanceof Boolean || (Modernizr[r2[0]] = new Boolean(Modernizr[r2[0]])), Modernizr[r2[0]][r2[1]] = a2), t.push((a2 ? "" : "no-") + r2.join("-"));
      }
  }
  function i(n2) {
    var e2 = r.className, s2 = Modernizr._config.classPrefix || "";
    if (c && (e2 = e2.baseVal), Modernizr._config.enableJSClass) {
      var o2 = new RegExp("(^|\\s)" + s2 + "no-js(\\s|$)");
      e2 = e2.replace(o2, "$1" + s2 + "js$2");
    }
    Modernizr._config.enableClasses && (e2 += " " + s2 + n2.join(" " + s2), c ? r.className.baseVal = e2 : r.className = e2);
  }
  var t = [], f = [], l = { _version: "3.3.1", _config: { classPrefix: "", enableClasses: true, enableJSClass: true, usePrefixes: true }, _q: [], on: function(n2, e2) {
    var s2 = this;
    setTimeout(function() {
      e2(s2[n2]);
    }, 0);
  }, addTest: function(n2, e2, s2) {
    f.push({ name: n2, fn: e2, options: s2 });
  }, addAsyncTest: function(n2) {
    f.push({ name: null, fn: n2 });
  } }, Modernizr = function() {
  };
  Modernizr.prototype = l, Modernizr = new Modernizr();
  var r = e.documentElement, c = "svg" === r.nodeName.toLowerCase();
  a(), i(t), delete l.addTest, delete l.addAsyncTest;
  for (var u = 0; u < Modernizr._q.length; u++)
    Modernizr._q[u]();
  n.Modernizr = Modernizr;
}(window, document);
