import dayjs from "dayjs";
$(() => {
  const $impersonationWarning = $("[data-impersonation-warning]");
  if ($impersonationWarning.length) {
    const endsAt = dayjs($impersonationWarning.data("session-ends-at"));
    const exitInterval = setInterval(() => {
      const diff = (endsAt - dayjs()) / 6e4;
      const diffInMinutes = Math.round(diff);
      $impersonationWarning.find(".minutes").html(diffInMinutes);
      if (diff <= 0) {
        window.location.reload();
      }
    }, 1e3);
    window.addEventListener("beforeunload", () => {
      clearInterval(exitInterval);
      return;
    });
  }
});
