var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import { Uploader } from "src/decidim/direct_uploads/uploader";
import icon from "src/decidim/icon";
import { truncateFilename } from "src/decidim/direct_uploads/upload_utility";
import { escapeHtml, escapeQuotes } from "src/decidim/utilities/text";
const STATUS = {
  VALIDATED: "validated",
  ERROR: "error"
};
export default class UploadModal {
  constructor(button, options = {}) {
    this.button = button;
    let providedOptions = {};
    try {
      providedOptions = JSON.parse(button.dataset.upload);
    } catch (_e) {
    }
    this.options = Object.assign(providedOptions, options);
    this.modal = document.querySelector(`#${button.dataset.dialogOpen}`);
    this.saveButton = this.modal.querySelector("button[data-dropzone-save]");
    this.cancelButton = this.modal.querySelector("button[data-dropzone-cancel]");
    this.modalTitle = this.modal.querySelector("[data-dialog-title]");
    this.dropZone = this.modal.querySelector("[data-dropzone]");
    this.emptyItems = this.modal.querySelector("[data-dropzone-no-items]");
    this.uploadItems = this.modal.querySelector("[data-dropzone-items]");
    this.input = this.dropZone.querySelector("input");
    this.items = [];
    this.attachmentCounter = 0;
    this.locales = JSON.parse(this.uploadItems.dataset.locales);
    this.updateDropZone();
  }
  uploadFiles(files) {
    if (this.options.multiple) {
      Array.from(files).forEach((file) => this.uploadFile(file));
    } else if (!this.uploadItems.children.length) {
      this.uploadFile(files[0]);
    }
  }
  uploadFile(file) {
    const uploader = new Uploader(this, {
      file,
      url: this.input.dataset.directUploadUrl,
      attachmentName: file.name
    });
    const item = this.createUploadItem(file, uploader.errors);
    this.uploadItems.appendChild(item);
    if (uploader.errors.length) {
      this.updateDropZone();
    } else {
      uploader.upload.create((error, blob) => {
        if (error) {
          uploader.errors = [error];
        } else {
          file.hiddenField = blob.signed_id;
          uploader.validate(blob.signed_id).then(() => {
            if (uploader.errors.length) {
              this.uploadItems.replaceChild(this.createUploadItem(file, uploader.errors, { value: 100 }), item);
            } else {
              this.items.push(file);
              this.autoloadImage(item, file);
            }
            this.updateDropZone();
          });
        }
      });
    }
  }
  autoloadImage(container, file) {
    if (!/image/.test(file.type)) {
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ({ target: { result } }) => {
      const img = container.querySelector("img");
      img.src = result;
    };
  }
  preloadFiles(element) {
    return __async(this, null, function* () {
      const { src } = element.querySelector("img") || {};
      let buffer = "";
      let type = "";
      if (src) {
        buffer = yield fetch(src).then((res) => res.arrayBuffer());
        type = "image";
      }
      const file = new File([buffer], element.dataset.filename, { type });
      const item = this.createUploadItem(file, [], __spreadProps(__spreadValues({}, element.dataset), { value: 100 }));
      file.attachmentId = element.dataset.attachmentId;
      file.hiddenField = element.dataset.hiddenField;
      this.items.push(file);
      this.uploadItems.appendChild(item);
      this.autoloadImage(item, file);
      this.updateDropZone();
    });
  }
  getOrdinalNumber() {
    const nextOrdinalNumber = this.attachmentCounter;
    this.attachmentCounter += 1;
    return nextOrdinalNumber;
  }
  updateDropZone() {
    const { children: files } = this.uploadItems;
    const inputHasFiles = files.length > 0;
    this.uploadItems.hidden = !inputHasFiles;
    this.saveButton.disabled = Array.from(files).filter(({ dataset: { state } }) => state === STATUS.ERROR).length > 0;
    const continueUpload = !files.length || this.options.multiple;
    this.input.disabled = !continueUpload;
    if (continueUpload) {
      this.emptyItems.classList.remove("is-disabled");
      this.emptyItems.querySelector("label").removeAttribute("disabled");
    } else {
      this.emptyItems.classList.add("is-disabled");
      this.emptyItems.querySelector("label").setAttribute("disabled", true);
    }
  }
  createUploadItem(file, errors, opts = {}) {
    const okTemplate = `
      <img src="" alt="${escapeQuotes(file.name)}" />
      <span>${escapeHtml(truncateFilename(file.name))}</span>
    `;
    const errorTemplate = `
      <div>${icon("error-warning-line")}</div>
      <div>
        <span>${escapeHtml(truncateFilename(file.name))}</span>
        <span>${this.locales.validation_error}</span>
        <ul>${errors.map((error) => `<li>${error}</li>`).join("\n")}</ul>
      </div>
    `;
    const titleTemplate = `
      <img src="" alt="${escapeQuotes(file.name)}" />
      <div>
        <div>
          <label>${this.locales.filename}</label>
          <span>${escapeHtml(truncateFilename(file.name))}</span>
        </div>
        <div>
          <label>${this.locales.title}</label>
          <input class="sm" type="text" value="${escapeQuotes(opts.title || truncateFilename(file.name))}" />
        </div>
      </div>
    `;
    let state = STATUS.VALIDATED;
    let content = okTemplate;
    let template = "ok";
    if (errors.length) {
      state = STATUS.ERROR;
      content = errorTemplate;
      template = "error";
    }
    if (!errors.length && this.options.titled) {
      content = titleTemplate;
      template = "titled";
    }
    const attachmentId = opts.attachmentId ? `data-attachment-id="${opts.attachmentId}"` : "";
    const fullTemplate = `
      <li ${attachmentId} data-filename="${escapeQuotes(file.name)}" data-state="${state}">
        <div data-template="${template}">
          ${content.trim()}
          <button>${this.locales.remove}</button>
        </div>
        <progress max="100" value="${opts.value || 0}"></progress>
      </li>`;
    const div = document.createElement("div");
    div.innerHTML = fullTemplate.trim();
    const container = div.firstChild;
    container.querySelector("button").addEventListener("click", this.handleButtonClick.bind(this));
    return container;
  }
  handleButtonClick({ currentTarget }) {
    const item = currentTarget.closest("[data-filename]");
    const { filename } = item.dataset;
    item.remove();
    const ix = this.items.findIndex(({ name }) => name === filename);
    if (ix > -1) {
      this.items[ix].removable = true;
    }
    this.updateDropZone();
  }
  setProgressBar(name, value) {
    this.uploadItems.querySelector(`[data-filename="${escapeQuotes(name)}"] progress`).value = value;
  }
  updateAddAttachmentsButton() {
    if (this.uploadItems.children.length === 0) {
      this.button.innerHTML = this.modalTitle.dataset.addlabel;
    } else {
      this.button.innerHTML = this.modalTitle.dataset.editlabel;
    }
    const requiredCheckbox = this.button.nextElementSibling;
    if (requiredCheckbox) {
      requiredCheckbox.checked = this.uploadItems.children.length > 0;
    }
  }
  cleanAllFiles() {
    this.items = [];
    this.uploadItems.textContent = "";
    this.updateDropZone();
  }
}
