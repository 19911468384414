import Rails from "@rails/ujs";
document.addEventListener("ajax:beforeSend", (ev) => {
  if (!ev.target.matches("form[data-remote]")) {
    return;
  }
  ev.target.querySelectorAll("[type=submit]").forEach((submit) => {
    submit.disabled = true;
  });
});
document.addEventListener("ajax:complete", (ev) => {
  if (!ev.target.matches("form[data-remote]")) {
    return;
  }
  ev.target.querySelectorAll("[type=submit]").forEach((submit) => {
    submit.disabled = false;
  });
});
$(document).on("submit", "form[data-remote][data-abide]", (ev) => {
  ev.preventDefault();
  if (ev.target.querySelectorAll("[data-invalid]").length > 0) {
    return;
  }
  Reflect.apply(Rails.handleRemote, ev.target, [ev]);
});
